@import '../../sass/variables';

.film-hero-banner {
  width: 100%;
  height: 43vh;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.related-links {
  font-size: 60px !important;

  a {
    color: $white;
    font-weight: bold;

    &:hover {
      color: $globalYellow;
      text-decoration: none;
    }
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .film-hero-banner {
    width: 100%;
    height: 23vh;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
