@import '../../sass/variables';

.standard-hero-banner {
  color: $white;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .smart-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  .dark-text {
    a {
      color: $white;
      font-weight: bold;

      &:hover,
      &:focus {
        color: lighten($newGray, 25%);
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.yellow-border {
  border-bottom: 15px solid $newGray;
}

.thumbnail {
  max-width: 100%;
}

@media only screen and (max-width: 468px) {
  .right {
    background-position: right bottom;
    background-color: $newGray;
    background-size: cover;
  }
  .left {
    background-position: left bottom;
    background-color: $newGray;
    background-size: cover;
  }
}
