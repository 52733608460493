@import '../../sass/variables';
.homepage-hero-banner {
  position: relative;
  
  .navigation-wrapper {
    [class^='the-slide'],
    [class*='the-slide'] {
      background: grey;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      height: 800px;
      background-position: center !important;
      background-repeat: no-repeat;
      background-size: cover !important;
    }

    .hero-slide {
      border-bottom: 15px solid $newGray;
    }

    .navigation-wrapper {
      position: relative;
      height: 100%;
    }

    .boxed {
      padding: 3%;
      background-color: $newGray;
    }

    .dots {
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }

    .dot {
      border: none;
      width: 10px;
      height: 10px;
      background: #c5c5c5;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
    }

    .dot:focus {
      outline: none;
    }

    .dot.active {
      background: $newGray;
    }

    .arrow {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      fill: $newGray;
      cursor: pointer;
    }

    .arrow--left {
      left: 5px;
    }

    .arrow--right {
      left: auto;
      right: 5px;
    }

    .arrow--disabled {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
}

@media only screen and (max-width: 768px) {
  .boxed {
    margin: 1.5%;
    padding: 5%;
  }

  .btn {
    width: 100%;
  }
}
