@import '../../sass/variables';

.team-module {
  color: $white;

  a {
    color: $globalYellow;

    &:hover,
    &:focus {
      color: $globalYellow;
      text-decoration: underline;
    }
  }

  .btn {
    font-size: 18px;
    color: $white;

    a {
      color: $white;

      &:hover,
      &:focus {
        color: $black;
        text-decoration: none;
      }
    }
  }

  .btn-yellow {
    color: $globalYellow;
  }

  .text-column {
    padding: 0 5px;

    &:first-of-type {
      padding-left: 5px;
    }

    &:last-of-type {
      padding-right: 5px;
    }
  }

  .dark-text {
    a {
      color: $black;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
