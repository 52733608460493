@import '../../sass/variables';

.footer {
  background-color: $newGray;
  border-top: 2px solid $white;

  a {
    color: $white !important;
    font-weight: 200;

    &:hover {
      color: lighten($newGray, 35%) !important;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    .social {
      float: left !important;
    }

    .pl-1,
    .px-1:first-of-type {
      padding-left: 0 !important;
      padding-right: 0.75rem;
    }
  }
}
