@import '../../sass/variables';

.video-media {
  color: $white;

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: $globalYellow;

    &:hover,
    &:focus {
      color: $globalYellow;
      text-decoration: underline;
    }
  }

  .dark-text {
    a {
      color: $black;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
