@import '../../sass/variables';

.quote {
  .quote-dark {
    background-color: #f3f6fb;
    color: $black;
  }

  .text-accent {
    color: #d6dbe1;
  }

  blockquote {
    border-left: 0;
    margin: 0 auto;
    max-width: 700px;

    p.quoteTitle {
      font-size: 24px;
      line-height: 30px;
      margin: 0 auto 40px;
      max-width: 530px;
    }

    span {
      &.leftquote {
        &::before {
          content: open-quote;
          float: left;
          font-size: 100px;
          font-weight: normal;
          margin-top: -50px;
        }

        &::after {
          content: close-quote;
          float: right;
          font-size: 100px;
          font-weight: normal;
          margin-top: -50px;
        }
      }
    }

    cite {
      font-style: normal;
      font-weight: normal;
    }
  }
}
