@import '../../sass/variables';

.slider-container {
  padding-bottom: 15px;
  width: 100%;
  float: left;

  a {
    color: $white !important;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%) !important;
      text-decoration: none !important;
    }
  }

  img {
    border: 3px solid $white;

    &:hover,
    &:focus {
      border: 3px solid lighten($newGray, 25%) !important;
    }
  }
}
