@import '../../sass/variables';

.testimonials {
  color: $black;

  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: non3;
    }
  }

  .btn {
    font-size: 18px;
    color: $newGray;

    a {
      color: $white;

      &:hover,
      &:focus {
        color: lighten($newGray, 25%);
        text-decoration: none;
      }
    }
  }

  h3 {
    padding: 0;
    margin: 0;
  }

  .btn-yellow {
    color: $globalYellow;
  }

  .text-column {
    padding: 0 5px;

    &:first-of-type {
      padding-left: 5px;
    }

    &:last-of-type {
      padding-right: 5px;
    }
  }

  .dark-text {
    a {
      color: $black;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
