@import '../../sass/variables';

.text-image-columns {
  color: $black;

  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: underline;
    }
  }

  .btn-yellow {
    color: $newGray;
  }

  .text-column {
    padding: 0 5px;
    padding-bottom: 15px;
    position: relative;

    &:first-of-type {
      padding-left: 5px;
    }

    &:last-of-type {
      padding-right: 5px;
    }

    .column-inner-content {
      padding-bottom: 80px;
    }

    .btn {
      font-size: 18px;
      color: $newGray;
      position: absolute;
      bottom: 0;
      width: 95% !important;

      > a {
        color: $white;

        &:hover,
        &:focus {
          color: $black;
          text-decoration: none;
        }
      }
    }
  }

  .dark-text {
    a {
      color: $white !important;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $white !important;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .text-image-columns {
    .text-column {
      .btn {
        width: 98% !important;
      }
    }
  }
}
