@import '../../sass/variables';

.standard-text {
  color: $black;
  font-weight: 200;

  a {
    color: $newGray;
    font-weight: 600;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  .dark-text {
    color: $white;
    
    a {
      color: $white;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $white;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
