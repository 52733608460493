@import '../../sass/variables';

.film-directory {
  color: $newGray;

  .film-container {
    padding: 0;
  }

  img {
    border: 3px solid $newGray;

    &:hover,
    &:focus {
      border: 3px solid lighten($newGray, 25%);
    }
  }

  .h3,
  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  p {
    color: $newGray;
  }
}

@media only screen and (max-width: 425px) {
  .film-directory {
    .film-container {
      padding: 0 15px;
    }
  }
}
