@import '../../sass/variables';

.navigation {
  background-color: $white;
  height: 79px;
  border-bottom: 3px solid $newGray;
  font-family: 'Cinzel', serif;

  img {
    height: 50px;
  }
}

.navigation-drop {
  background-color: $newGray;

  a {
    color: $white;
    font-family: 'Sarabun', sans-serif;
    font-weight: 200;

    &:hover {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  .grid-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 20px 0;

    li {
      padding: 20px 0;
    }
  }
}

.mobile-logo {
  height: 50px;
}

button {
  background: none;
  border: none;
  color: $white;
  padding: 0;
  margin: 0;

  &:hover {
    color: $globalYellow;
  }
}

.hamburger {
  font-size: 32px;
}

.active {
  display: block;
  transition: display 0.5s linear;
}

.inactive {
  display: none;
  transition: display 0.5s linear;
}

@media only screen and (max-width: 991px) {
  .navigation {
    img {
      height: 40px;
      max-width: auto;
    }
  }
}

@media only screen and (max-width: 425px) {
  .navigation {
    img {
      height: auto;
      max-width: 100%;
    }
  }
  .navigation-drop {
    .grid-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 20px 0;
    }
  }
}
