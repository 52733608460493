@import '../../sass/variables';

.icon {
  background-repeat: no-repeat;
  background-size: contain;

  &.inline {
    display: flex;
    height: 20px;
    min-width: 20px;
  }
}

.img-icon {
  max-width: 15px;
}
