@import '../../sass/variables';

.any-code {
  color: $white;

  a {
    color: $globalYellow;

    &:hover,
    &:focus {
      color: $globalYellow;
      text-decoration: underline;
    }
  }

  .dark-text {
    a {
      color: $black;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
