@import '../../sass/variables';

.gallery {
  [class^='the-slide'],
  [class*='the-slide'] {
    background: white;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: $newGray;
  }

  .arrow-gallery {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    transform: translateY(-35%);
    -webkit-transform: translateY(-35%);
    fill: $newGray;
    cursor: pointer;
  }

  .arrow-gallery-left {
    left: 5px;
  }

  .arrow-gallery-right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }

  .thumbnail .keen-slider__slide {
    border: 3px solid transparent;
    font-size: 30px;
    margin-top: 10px;
  }

  .thumbnail .keen-slider__slide.active {
    border: 3px solid $newGray;
  }
}

@media only screen and (max-width: 768px) {
  .boxed {
    margin: 1.5%;
    padding: 5%;
  }

  .btn {
    width: 100%;
  }
}
