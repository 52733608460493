@import '../../sass/variables';

.home-hero-banner {
  color: $white;
  height: 800px;
  background-position: top center;
  background-repeat: no-repeat;

  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  .dark-text {
    a {
      color: $black;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $black;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

.yellow-border {
  border-bottom: 15px solid $newGray !important;
}

@media only screen and (max-width: 991px) {
  .home-hero-banner {
    height:500px;
    max-height: 100%;
    background-size: cover;
  }
}

@media only screen and (max-width: 767px) {
  .home-hero-banner {
    height:450px;
    max-height: 100%;
    background-size: cover;
  }
}

@media only screen and (max-width: 425px) {
  .home-hero-banner {
    height:250px;
    max-height: 100%;
    background-size: cover;
  }
}
