@import '../../sass/variables';

.book-directory {
  color: $white;

  img {
    border: 3px solid $newGray;
    max-height: 370px;
    margin: 0 auto;

    &:hover,
    &:focus {
      border: 3px solid lighten($newGray, 25%);
    }
  }

  .h3,
  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  p {
    color: $white;
  }
}

@media only screen and (max-width: 768px) {
  .book-directory {
    .img {
      width: unset !important;
      max-height: unset !important;
    }
  }
}
