@import '../../sass/variables';

.slider-container {
  padding-bottom: 15px;
  width: 100%;
  float: left;

  a {
    color: $newGray !important;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  img {
    border: 3px solid $newGray;

    &:hover,
    &:focus {
      border: 3px solid lighten($newGray, 25%);
    }
  }
}
