@import '../../sass/variables';

.music-video-directory {
  color: $black;

  img {
    border: 3px solid $newGray;

    &:hover,
    &:focus {
      border: 3px solid lighten($newGray, 25%);
    }
  }

  .h3,
  a {
    color: $newGray;

    &:hover,
    &:focus {
      color: lighten($newGray, 25%);
      text-decoration: none;
    }
  }

  p {
    color: $black;
  }
}
