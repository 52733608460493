// Basic colors
$red: #f00;
$white: #ffffff;
$black: #000000;
$gray: #808080;
$darkGrey: #1d1c1c;
$blue: #009fe3;

// Custom colors
$globalYellow: #ddcc0c; // old
$newGray: #63747a;

// Colors of use
$errorRed: #db4e3c;
$successGreen: #3cb878;
$warningYellow: #fbbb10;
$borderGrey: #898989;
$borderBlue: #009edb;
$altBgGrey: #f4f7fa;
$gold: #e8c271;
$teal: #67bac8;
$aqua: #00fcf0;
