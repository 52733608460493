@import '~bootstrap/scss/bootstrap.scss';
@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  background-color: #fefefe;
  color: #000;
  font-family: 'Sarabun', sans-serif;
  font-weight: 200;
  margin: 0;
  padding: 0;
}

@for $index from 1 through 6 {
  h#{$index},
  .h#{$index} {
    //color: $newGray;
    font-weight: 400;
    font-family: 'Cinzel', serif !important;

    a {
      color: $newGray;

      &:hover,
      &:focus {
        color: lighten($newGray, 25%);
        text-decoration: none;
      }
    }
  }
}

a {
  color: $newGray;
  font-weight: bold;

  &:hover {
    color: lighten($newGray, 25%);
    text-decoration: none;
  }
}

.text-new-grey {
  color: $newGray !important;

  &:hover,
  &:focus {
    color: lighten($newGray, 25%);
    text-decoration: none;
  }
}

.btn {
  background-color: transparent;
  border: 2px solid $newGray;
  border-radius: 0;
  box-shadow: none !important;
  color: $white;
  font-size: 14px;
  margin-bottom: 25px;
  margin-right: 16px;
  min-width: 120px;
  padding: 12px 30px;
  text-align: center;
  border-radius: 0.25rem;
  font-family: 'Cinzel', serif !important;

  &:hover,
  &:focus {
    background-color: $newGray;
    color: $white !important;
  }

  &:active {
    opacity: 0.8;
  }

  :last-of-type {
    margin-right: 1px;
  }
}

/* wide */

.btn-bold {
  font-weight: bold;
}

.btn-wide {
  padding-left: 45px;
  padding-right: 45px;
}

/* light */

.btn-yellow {
  background-color: $globalYellow;
  border: 2px solid $globalYellow;
  color: $black;

  &:hover,
  &:focus {
    background-color: $black;
    border: 2px solid $globalYellow;
    color: $globalYellow;
  }
}

.btn-grey {
  background-color: $newGray;
  border: 2px solid $newGray;
  color: $white;

  &:hover,
  &:focus {
    background-color: transparent;
    border: 2px solid $newGray;
    color: $newGray !important;
  }
}

.btn-outline-grey {
  background-color: transparent;
  border: 2px solid $newGray;
  color: $newGray;

  &:hover,
  &:focus {
    background-color: $newGray;
    border: 2px solid $newGray;
    color: $white !important;
  }
}

/* dark */

.btn-dark {
  background-color: transparent;
  border: 2px solid $white;
  color: $white !important;

  &:hover,
  &:focus {
    background-color: $white !important;
    border: 2px solid $white !important;
    color: $newGray !important;
  }
}

/* modifiers */

.btn-lg {
  font-size: 20px;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, $newGray 10%, rgb(255, 255, 255) 42%);
  background: -webkit-linear-gradient(
    left,
    $newGray 10%,
    rgb(255, 255, 255) 42%
  );
  background: -o-linear-gradient(left, $newGray 10%, rgb(255, 255, 255) 42%);
  background: -ms-linear-gradient(left, $newGray 10%, rgb(255, 255, 255) 42%);
  background: linear-gradient(to right, $newGray 10%, rgb(255, 255, 255) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: rgb(255, 255, 255);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: rgb(255, 255, 255);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.slick-arrow {
  background: none;
  border: none;
  position: absolute;
  left: -35px;
  top: 40%;
  width: 20px;
  height: 50px;
  padding: 0;
  overflow: hidden;
  text-indent: -9999px;
  transition: all 0.5s ease;
  opacity: 0.9;

  &:hover,
  &:focus {
    opacity: 1;
    border: 1px solid #009edb;
  }
}

.slick-arrow::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 !important;
  line-height: 1em;
  font-size: 30px;
  content: '❮';
  text-align: center;
  text-indent: 0px;
  color: $newGray;
}

.slick-next {
  left: auto;
  right: -55px;
}

.slick-next::before {
  content: '❯';
  text-indent: -1px;
}

.slick-prev::before {
  content: '❮';
  text-indent: 0;
}

.slick-disabled {
  opacity: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px !important;
}

/* dots */

.slick-dots {
  padding: 0;
  padding-top: 25px;
  list-style: none;
  text-align: center;
}

.slick-dots > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.slick-dots > li::before {
  display: none !important;
}

.slick-dots > li > button {
  background: none;
  width: 10px;
  height: 10px;
  border: 1px solid $newGray !important;
  overflow: hidden;
  text-indent: -9999px;
  padding: 0;
  border-radius: 50%;
  display: block;
}

.slick-dots > .slick-active > button,
.slick-dots > li > button:hover,
.slick-dots > li > button:focus {
  background-color: $newGray !important;
}

@media only screen and (max-width: 1440px) {
  .slick-arrow {
    left: -25px;
  }

  .slick-next {
    left: auto;
    right: -25px;
  }
}

@media only screen and (max-width: 1200px) {
  .slick-arrow {
    left: -20px;
    top: 130px;
  }

  .slick-next {
    left: auto;
    right: -20px;
  }
}

@media only screen and (max-width: 992px) {
  .slick-arrow,
  .slick-next {
    display: none !important;
  }
}
